import React from "react";
import counterIconOne from "../assets/images/icon/counter-icon-1.svg";
import counterIconTwo from "../assets/images/icon/counter-icon-2.svg";
import counterIconThree from "../assets/images/icon/counter-icon-3.svg";
import counterIconFour from "../assets/images/icon/counter-icon-4.svg";
import CounterUpCom from "../lib/CounterUpCom.jsx";

function CounterArea({ style }) {
  return (
    <>
      <section
        className="appie-counter-area pt-90 pb-190"
        id="counter-vd"
        style={style}
      >
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="appie-section-title">
                <h3 className="appie-title">Develop your project</h3>
                <p>
                  Our platform guides you through every stage of the development
                  life cycle, from data collection to site optimisation.
                </p>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-3 col-md-6">
              <div
                className="appie-single-counter mt-30 wow animated fadeInUp"
                data-wow-duration="2000ms"
                data-wow-delay="200ms"
              >
                <div className="counter-content">
                  {/* <div className="icon">
                    <img src={counterIconOne} alt="" />
                  </div> */}
                  <h3 className="title">
                    <span className="counter-item">
                      <CounterUpCom endValue={100} sectionSelect="counter-vd" />
                    </span>
                    +
                  </h3>
                  <p>Access trusted global datasets</p>
                </div>
              </div>
            </div>
            <div className="col-lg-3 col-md-6">
              <div
                className="appie-single-counter mt-30 item-2 wow animated fadeInUp"
                data-wow-duration="2000ms"
                data-wow-delay="400ms"
              >
                <div className="counter-content">
                  {/* <div className="icon">
                    <img src={counterIconTwo} alt="" />
                  </div> */}
                  <h3 className="title">
                    &lt;
                    <span className="counter-item pl-10">
                      <CounterUpCom endValue={60} sectionSelect="counter-vd" />
                    </span>
                    s
                  </h3>
                  <p>Build a fixed or floating site quickly and easily</p>
                </div>
              </div>
            </div>
            <div className="col-lg-3 col-md-6">
              <div
                className="appie-single-counter mt-30 item-3 wow animated fadeInUp"
                data-wow-duration="2000ms"
                data-wow-delay="600ms"
              >
                <div className="counter-content">
                  {/* <div className="icon">
                    <img src={counterIconThree} alt="" />
                  </div> */}
                  <h3 className="title">
                    &lt;
                    <span className="counter-item pl-10">
                      <CounterUpCom endValue={60} sectionSelect="counter-vd" />
                    </span>
                    s
                  </h3>
                  <p>Analyse site weather and elevation data</p>
                </div>
              </div>
            </div>
            <div className="col-lg-3 col-md-6">
              <div
                className="appie-single-counter mt-30 item-4 wow animated fadeInUp"
                data-wow-duration="2000ms"
                data-wow-delay="800ms"
              >
                <div className="counter-content">
                  {/* <div className="icon">
                    <img src={counterIconFour} alt="" />
                  </div> */}
                  <h3 className="title">
                    &lt;
                    <span className="counter-item pl-10">
                      <CounterUpCom endValue={60} sectionSelect="counter-vd" />
                    </span>
                    s
                  </h3>
                  <p>Run customised yield analysis quickly and easily</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default CounterArea;
