import React, { useEffect, useState } from "react";
import { getPortalUrl, handleToolLaunch } from "../helperFunctions";
import { useAuthState } from "react-firebase-hooks/auth";
import { app, auth, db } from "../../firebase";

import { BeatLoader } from "react-spinners";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheckCircle } from "@fortawesome/free-solid-svg-icons";
import { getCheckoutUrl } from "../../vektaDigital/sidebar/modals/vektaStore/helperFunctions";
import { doc, getDoc } from "firebase/firestore";
import { getFunctions, httpsCallable } from "firebase/functions";

function PricingHomeOne({
  className,
  setLoginSelected,
  setPage,
  toolInfo,
  homePage,
  setHomePage,
}) {
  const [toggleSwitch, setSwitchValue] = useState(true);
  const [user, loading, error] = useAuthState(auth);

  const [loadingCheckout, setLoadingCheckout] = useState({
    month: false,
    year: false,
    monthTrial: false,
    yearTrial: false,
  });

  const [paymentOption, setPaymentOption] = useState({
    month: { price: 50, priceID: "price_1QHwCOIowKhyLz4fNDOhqYjx" },
    year: { price: 550, priceID: "price_1QHwCOIowKhyLz4fOashrMgA" },
  });

  const [activatedDeals, setActivatedDeals] = useState(0);

  const handleToolBuy = async (tool, interval, trial = false) => {
    console.log(tool);
    if (user) {
      setLoadingCheckout((loadingCheckout) => ({
        ...loadingCheckout,
        [interval + (trial ? "Trial" : "")]: true,
      }));

      console.log(trial);

      const checkoutUrl = await getCheckoutUrl(
        paymentOption[interval].priceID,
        trial
      );
      console.log(checkoutUrl);
      window.location.href = checkoutUrl.url;

      setLoadingCheckout({
        month: false,
        year: false,
        monthTrial: false,
        yearTrial: false,
      });
      return;
    }

    setLoginSelected(true);
  };

  useEffect(() => {
    const checkActivations = async () => {
      const activated_deals = (
        await getDoc(doc(db, "accessControl", "active_deals"))
      ).data().active_deals;

      setActivatedDeals(activated_deals.length);
    };

    checkActivations();
  }, []);

  return (
    <>
      <section
        id="pricing-cards"
        className={`appie-pricing-area pt-90 pb-90 ${className || ""}`}
      >
        <div className="container">
          <div className="row">
            <div className="col-lg-12 pb-50">
              <div className="appie-section-title text-center">
                <h3 className="appie-title">Choose your plan</h3>
              </div>
              <ul
                className="list-inline text-center switch-toggler-list"
                role="tablist"
                id="switch-toggle-tab"
              >
                <li className={`month ${toggleSwitch ? "active" : ""}`}>
                  <a href="#">Monthly</a>
                </li>
                <li>
                  <label
                    onClick={() => setSwitchValue(!toggleSwitch)}
                    className={`switch ${toggleSwitch ? "on" : "off"}`}
                  >
                    <span className="slider round" />
                  </label>
                </li>
                <li
                  className={`year ${toggleSwitch === false ? "active" : ""}`}
                >
                  <a href="#">Annually</a>
                </li>
              </ul>
            </div>
          </div>
          <div className="tabed-content">
            {toggleSwitch ? (
              <div id="month">
                <div className="row justify-content-center align-items-center">
                  <div className="col-lg-4 col-md-6 wow animated fadeInUp">
                    <div className="pricing-one__single center">
                      <div className="pricig-heading">
                        <h6 style={{ margin: "0" }}>Vekta Digital</h6>
                        <div className="price-range">
                          <sup></sup> <span>Free</span>
                        </div>
                      </div>
                      <div className="pricig-body">
                        <ul>
                          <li>
                            <FontAwesomeIcon
                              icon={faCheckCircle}
                              color="lightgreen"
                              size="lg"
                            />{" "}
                            Global Datasets (BASIC)
                          </li>
                          <li>
                            <FontAwesomeIcon
                              icon={faCheckCircle}
                              color="lightgreen"
                              size="lg"
                            />{" "}
                            Weather Analysis (BASIC)
                          </li>
                          <li>
                            <FontAwesomeIcon
                              icon={faCheckCircle}
                              color="lightgreen"
                              size="lg"
                            />{" "}
                            Elevation Analysis
                          </li>
                          <li>
                            <FontAwesomeIcon
                              icon={faCheckCircle}
                              color="lightgreen"
                              size="lg"
                            />{" "}
                            Data Search Filter (BASIC)
                          </li>
                          <li>
                            <FontAwesomeIcon
                              icon={faCheckCircle}
                              color="lightgreen"
                              size="lg"
                            />{" "}
                            Vekta Yield (BASIC)
                          </li>
                          <li>
                            <FontAwesomeIcon
                              icon={faCheckCircle}
                              color="grey"
                              size="lg"
                            />{" "}
                            Project Sharing
                          </li>
                          <li>
                            <FontAwesomeIcon
                              icon={faCheckCircle}
                              color="grey"
                              size="lg"
                            />{" "}
                            Project Dashboard
                          </li>
                          <li>
                            <FontAwesomeIcon
                              icon={faCheckCircle}
                              color="grey"
                              size="lg"
                            />{" "}
                            Custom Map Drawing
                          </li>
                          <li>
                            <FontAwesomeIcon
                              icon={faCheckCircle}
                              color="grey"
                              size="lg"
                            />{" "}
                            Multi-user Account
                          </li>
                          <li>
                            <FontAwesomeIcon
                              icon={faCheckCircle}
                              color="grey"
                              size="lg"
                            />{" "}
                            UK & Ireland Datasets
                          </li>
                          <li>
                            <FontAwesomeIcon
                              icon={faCheckCircle}
                              color="grey"
                              size="lg"
                            />{" "}
                            Site Comparison
                          </li>
                          <li>
                            <FontAwesomeIcon
                              icon={faCheckCircle}
                              color="grey"
                              size="lg"
                            />{" "}
                            Additional Modules
                          </li>
                          <li>
                            <FontAwesomeIcon
                              icon={faCheckCircle}
                              color="grey"
                              size="lg"
                            />{" "}
                            Engineering Design Support
                          </li>
                          <li>
                            <FontAwesomeIcon
                              icon={faCheckCircle}
                              color="grey"
                              size="lg"
                            />{" "}
                            Module Bundle Discounts
                          </li>
                          <li>
                            <FontAwesomeIcon
                              icon={faCheckCircle}
                              color="grey"
                              size="lg"
                            />{" "}
                            Early Access & Beta Testing
                          </li>
                        </ul>
                      </div>
                      <div className="pricing-btn mt-35">
                        <a
                          className="main-btn"
                          style={{ width: "100%" }}
                          href="#"
                          onClick={() =>
                            handleToolLaunch(setLoginSelected, user)
                          }
                        >
                          Launch Tool
                        </a>
                      </div>
                      {/* <div className="pricing-rebon">
                        <span>Most Popular</span>
                      </div> */}
                    </div>
                  </div>
                  <div className="col-lg-4 col-md-6 wow animated fadeInRight">
                    <div className="pricing-one__single center">
                      <div className="pricig-heading">
                        <h6 style={{ margin: "0" }}>Vekta Digital +</h6>
                        <div className="price-range">
                          {/* {activatedDeals >= 100 ? ( */}
                          <>
                            <sup>£</sup> <span>50</span>
                            <p>/user per month</p>
                          </>
                          {/* ) : (
                            <>
                              <span>Free</span>
                              <p>/2nd Dec - 2nd Jan</p>
                            </>
                          )} */}
                          {/* {activatedDeals >= 100 && ( */}
                          <p style={{ margin: "0", fontSize: "12px" }}>
                            Pricing varies based on subscription volume, with
                            discounts available
                          </p>
                          {/* )} */}
                        </div>
                      </div>
                      <div className="pricig-body">
                        <ul>
                          <li>
                            <FontAwesomeIcon
                              icon={faCheckCircle}
                              color="lightgreen"
                              size="lg"
                            />{" "}
                            Global Datasets
                          </li>
                          <li>
                            <FontAwesomeIcon
                              icon={faCheckCircle}
                              color="lightgreen"
                              size="lg"
                            />{" "}
                            Weather Analysis
                          </li>
                          <li>
                            <FontAwesomeIcon
                              icon={faCheckCircle}
                              color="lightgreen"
                              size="lg"
                            />{" "}
                            Elevation Analysis
                          </li>
                          <li>
                            <FontAwesomeIcon
                              icon={faCheckCircle}
                              color="lightgreen"
                              size="lg"
                            />{" "}
                            Data Search
                          </li>
                          <li>
                            <FontAwesomeIcon
                              icon={faCheckCircle}
                              color="lightgreen"
                              size="lg"
                            />{" "}
                            Vekta Yield
                          </li>
                          <li>
                            <FontAwesomeIcon
                              icon={faCheckCircle}
                              color="lightgreen"
                              size="lg"
                            />{" "}
                            Project Sharing
                          </li>
                          <li>
                            <FontAwesomeIcon
                              icon={faCheckCircle}
                              color="lightgreen"
                              size="lg"
                            />{" "}
                            Project Dashboard
                          </li>
                          <li>
                            <FontAwesomeIcon
                              icon={faCheckCircle}
                              color="lightgreen"
                              size="lg"
                            />{" "}
                            Custom Map Drawing
                          </li>
                          <li>
                            <FontAwesomeIcon
                              icon={faCheckCircle}
                              color="lightgreen"
                              size="lg"
                            />{" "}
                            Multi-user Account (1 - 50)
                          </li>
                          <li>
                            <FontAwesomeIcon
                              icon={faCheckCircle}
                              color="lightgreen"
                              size="lg"
                            />{" "}
                            UK & Ireland Datasets
                          </li>
                          <li>
                            <FontAwesomeIcon
                              icon={faCheckCircle}
                              color="lightgreen"
                              size="lg"
                            />{" "}
                            Site Comparison
                          </li>
                          <li>
                            <FontAwesomeIcon
                              icon={faCheckCircle}
                              color="lightgreen"
                              size="lg"
                            />{" "}
                            Additional Modules
                          </li>
                          <li>
                            <FontAwesomeIcon
                              icon={faCheckCircle}
                              color="grey"
                              size="lg"
                            />{" "}
                            Engineering Design Support
                          </li>
                          <li>
                            <FontAwesomeIcon
                              icon={faCheckCircle}
                              color="grey"
                              size="lg"
                            />{" "}
                            Module Bundle Discounts
                          </li>
                          <li>
                            <FontAwesomeIcon
                              icon={faCheckCircle}
                              color="grey"
                              size="lg"
                            />{" "}
                            Early Access & Beta Testing
                          </li>
                        </ul>
                      </div>
                      <div className="pricing-btn mt-35">
                        {/* {activatedDeals >= 100 ? ( */}
                        <a
                          className="main-btn"
                          style={{ width: "100%" }}
                          href="#"
                          onClick={() =>
                            handleToolBuy(
                              Object.keys(toolInfo).filter(
                                (tool) =>
                                  toolInfo[tool].info.name === "Vekta Digital +"
                              ),
                              "month"
                            )
                          }
                        >
                          {loadingCheckout.month ? (
                            <BeatLoader
                              style={{ height: "100%" }}
                              color="white"
                            />
                          ) : (
                            "Buy Now"
                          )}
                        </a>
                        {/* ) : (
                          <div
                            style={{
                              display: "flex",
                              flexDirection: "column",
                              gap: "8px",
                            }}
                          >
                            <span>
                              * Deal only applies to the first 100{" "}
                              <u>activated</u> product keys
                            </span>
                            <a
                              className="main-btn"
                              href="/#"
                              onClick={() => {
                                if (user) {
                                  const functions = getFunctions(
                                    app,
                                    "us-central1"
                                  );
                                  const dealProduct = httpsCallable(
                                    functions,
                                    "getDealProductKey"
                                  );

                                  setLoadingCheckout((loadingCheckout) => ({
                                    ...loadingCheckout,
                                    month: true,
                                  }));

                                  dealProduct()
                                    .then((result) => {
                                      console.log("Product key sent");
                                      setLoadingCheckout((loadingCheckout) => ({
                                        ...loadingCheckout,
                                        month: false,
                                      }));
                                    })
                                    .catch((error) => {
                                      // Getting the Error details.
                                      console.log(error.code);
                                    });
                                  console.log("Send a Product Key to user");
                                }
                                setLoginSelected(true);
                              }}
                            >
                              {loadingCheckout.month ? (
                                <BeatLoader
                                  style={{ height: "100%" }}
                                  color="white"
                                />
                              ) : (
                                "Activate Deal"
                              )}
                            </a>
                          </div>
                        )} */}
                      </div>
                      <div className="pricing-rebon">
                        <span
                          style={{
                            fontWeight: "600",
                            // background:
                            //   "linear-gradient(90deg, rgba(0,232,255,1) 0%, rgba(255,0,254,1) 100%)",
                          }}
                        >
                          Most Popular
                        </span>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-4 col-md-6 wow animated fadeInRight">
                    <div className="pricing-one__single center">
                      <div className="pricig-heading">
                        <h6 style={{ margin: "0" }}>Enterprise</h6>
                        <div className="price-range">
                          <sup></sup> <span>Contact Us</span>
                        </div>
                      </div>
                      <div className="pricig-body">
                        <ul>
                          <li>
                            <FontAwesomeIcon
                              icon={faCheckCircle}
                              color="lightgreen"
                              size="lg"
                            />{" "}
                            Global Datasets
                          </li>
                          <li>
                            <FontAwesomeIcon
                              icon={faCheckCircle}
                              color="lightgreen"
                              size="lg"
                            />{" "}
                            Weather Analysis
                          </li>
                          <li>
                            <FontAwesomeIcon
                              icon={faCheckCircle}
                              color="lightgreen"
                              size="lg"
                            />{" "}
                            Elevation Analysis
                          </li>
                          <li>
                            <FontAwesomeIcon
                              icon={faCheckCircle}
                              color="lightgreen"
                              size="lg"
                            />{" "}
                            Data Search
                          </li>
                          <li>
                            <FontAwesomeIcon
                              icon={faCheckCircle}
                              color="lightgreen"
                              size="lg"
                            />{" "}
                            Vekta Yield
                          </li>
                          <li>
                            <FontAwesomeIcon
                              icon={faCheckCircle}
                              color="lightgreen"
                              size="lg"
                            />{" "}
                            Project Sharing
                          </li>
                          <li>
                            <FontAwesomeIcon
                              icon={faCheckCircle}
                              color="lightgreen"
                              size="lg"
                            />{" "}
                            Project Dashboard
                          </li>
                          <li>
                            <FontAwesomeIcon
                              icon={faCheckCircle}
                              color="lightgreen"
                              size="lg"
                            />{" "}
                            Custom Map Drawing
                          </li>
                          <li>
                            <FontAwesomeIcon
                              icon={faCheckCircle}
                              color="lightgreen"
                              size="lg"
                            />{" "}
                            Multi-user Account (50+)
                          </li>
                          <li>
                            <FontAwesomeIcon
                              icon={faCheckCircle}
                              color="lightgreen"
                              size="lg"
                            />{" "}
                            UK & Ireland Datasets
                          </li>
                          <li>
                            <FontAwesomeIcon
                              icon={faCheckCircle}
                              color="lightgreen"
                              size="lg"
                            />{" "}
                            Site Comparison
                          </li>
                          <li>
                            <FontAwesomeIcon
                              icon={faCheckCircle}
                              color="lightgreen"
                              size="lg"
                            />{" "}
                            Additional Modules
                          </li>
                          <li>
                            <FontAwesomeIcon
                              icon={faCheckCircle}
                              color="lightgreen"
                              size="lg"
                            />{" "}
                            Engineering Design Support
                          </li>
                          <li>
                            <FontAwesomeIcon
                              icon={faCheckCircle}
                              color="lightgreen"
                              size="lg"
                            />{" "}
                            Module Bundle Discounts
                          </li>
                          <li>
                            <FontAwesomeIcon
                              icon={faCheckCircle}
                              color="lightgreen"
                              size="lg"
                            />{" "}
                            Early Access & Beta Testing
                          </li>
                        </ul>
                      </div>
                      <div className="pricing-btn mt-35">
                        <a
                          className="main-btn"
                          style={{ width: "100%" }}
                          href="#"
                          onClick={() => {
                            setPage("contact");
                            document.getElementById(
                              "landing-page"
                            ).scrollTop = 0;
                          }}
                        >
                          Contact Us
                        </a>
                      </div>
                      {/* <div className="pricing-rebon">
                        <span>Coming Soon</span>
                      </div> */}
                    </div>
                  </div>
                </div>
              </div>
            ) : (
              <div id="year">
                <div className="row justify-content-center align-items-center">
                  <div className="col-lg-4 col-md-6 wow animated fadeInUp">
                    <div className="pricing-one__single center">
                      <div className="pricig-heading">
                        <h6 style={{ margin: "0" }}>Vekta Digital</h6>
                        <div className="price-range">
                          <sup></sup> <span>Free</span>
                        </div>
                      </div>
                      <div className="pricig-body">
                        <ul>
                          <li>
                            <FontAwesomeIcon
                              icon={faCheckCircle}
                              color="lightgreen"
                              size="lg"
                            />{" "}
                            Global Datasets (BASIC)
                          </li>
                          <li>
                            <FontAwesomeIcon
                              icon={faCheckCircle}
                              color="lightgreen"
                              size="lg"
                            />{" "}
                            Weather Analysis (BASIC)
                          </li>
                          <li>
                            <FontAwesomeIcon
                              icon={faCheckCircle}
                              color="lightgreen"
                              size="lg"
                            />{" "}
                            Elevation Analysis
                          </li>
                          <li>
                            <FontAwesomeIcon
                              icon={faCheckCircle}
                              color="lightgreen"
                              size="lg"
                            />{" "}
                            Data Search Filter (BASIC)
                          </li>
                          <li>
                            <FontAwesomeIcon
                              icon={faCheckCircle}
                              color="lightgreen"
                              size="lg"
                            />{" "}
                            Vekta Yield (BASIC)
                          </li>
                          <li>
                            <FontAwesomeIcon
                              icon={faCheckCircle}
                              color="grey"
                              size="lg"
                            />{" "}
                            Project Sharing
                          </li>
                          <li>
                            <FontAwesomeIcon
                              icon={faCheckCircle}
                              color="grey"
                              size="lg"
                            />{" "}
                            Project Dashboard
                          </li>
                          <li>
                            <FontAwesomeIcon
                              icon={faCheckCircle}
                              color="grey"
                              size="lg"
                            />{" "}
                            Custom Map Drawing
                          </li>
                          <li>
                            <FontAwesomeIcon
                              icon={faCheckCircle}
                              color="grey"
                              size="lg"
                            />{" "}
                            Multi-user Account
                          </li>
                          <li>
                            <FontAwesomeIcon
                              icon={faCheckCircle}
                              color="grey"
                              size="lg"
                            />{" "}
                            UK & Ireland Datasets
                          </li>
                          <li>
                            <FontAwesomeIcon
                              icon={faCheckCircle}
                              color="grey"
                              size="lg"
                            />{" "}
                            Site Comparison
                          </li>
                          <li>
                            <FontAwesomeIcon
                              icon={faCheckCircle}
                              color="grey"
                              size="lg"
                            />{" "}
                            Additional Modules
                          </li>
                          <li>
                            <FontAwesomeIcon
                              icon={faCheckCircle}
                              color="grey"
                              size="lg"
                            />{" "}
                            Engineering Design Support
                          </li>
                          <li>
                            <FontAwesomeIcon
                              icon={faCheckCircle}
                              color="grey"
                              size="lg"
                            />{" "}
                            Module Bundle Discounts
                          </li>
                          <li>
                            <FontAwesomeIcon
                              icon={faCheckCircle}
                              color="grey"
                              size="lg"
                            />{" "}
                            Early Access & Beta Testing
                          </li>
                        </ul>
                      </div>
                      <div className="pricing-btn mt-35">
                        <a
                          className="main-btn"
                          style={{ width: "100%" }}
                          href="#"
                          onClick={() =>
                            handleToolLaunch(setLoginSelected, user)
                          }
                        >
                          Launch Tool
                        </a>
                      </div>
                      {/* <div className="pricing-rebon">
                        <span>Most Popular</span>
                      </div> */}
                    </div>
                  </div>
                  <div className="col-lg-4 col-md-6 wow animated fadeInRight">
                    <div className="pricing-one__single center">
                      <div className="pricig-heading">
                        <h6 style={{ margin: "0" }}>Vekta Digital +</h6>
                        <div className="price-range">
                          {/* {activatedDeals >= 100 ? ( */}
                          <>
                            <sup>£</sup> <span>550</span>
                            <p>/user per year</p>
                          </>
                          {/* ) : (
                            <>
                              <span>Free</span>
                              <p>/2nd Dec - 2nd Jan</p>
                            </>
                          )} */}
                          <p style={{ margin: "0", fontSize: "12px" }}>
                            Pricing varies based on subscription volume, with
                            discounts available
                          </p>
                        </div>
                      </div>
                      <div className="pricig-body">
                        <ul>
                          <li>
                            <FontAwesomeIcon
                              icon={faCheckCircle}
                              color="lightgreen"
                              size="lg"
                            />{" "}
                            Global Datasets
                          </li>
                          <li>
                            <FontAwesomeIcon
                              icon={faCheckCircle}
                              color="lightgreen"
                              size="lg"
                            />{" "}
                            Weather Analysis
                          </li>
                          <li>
                            <FontAwesomeIcon
                              icon={faCheckCircle}
                              color="lightgreen"
                              size="lg"
                            />{" "}
                            Elevation Analysis
                          </li>
                          <li>
                            <FontAwesomeIcon
                              icon={faCheckCircle}
                              color="lightgreen"
                              size="lg"
                            />{" "}
                            Data Search
                          </li>
                          <li>
                            <FontAwesomeIcon
                              icon={faCheckCircle}
                              color="lightgreen"
                              size="lg"
                            />{" "}
                            Vekta Yield
                          </li>
                          <li>
                            <FontAwesomeIcon
                              icon={faCheckCircle}
                              color="lightgreen"
                              size="lg"
                            />{" "}
                            Project Sharing
                          </li>
                          <li>
                            <FontAwesomeIcon
                              icon={faCheckCircle}
                              color="lightgreen"
                              size="lg"
                            />{" "}
                            Project Dashboard
                          </li>
                          <li>
                            <FontAwesomeIcon
                              icon={faCheckCircle}
                              color="lightgreen"
                              size="lg"
                            />{" "}
                            Custom Map Drawing
                          </li>
                          <li>
                            <FontAwesomeIcon
                              icon={faCheckCircle}
                              color="lightgreen"
                              size="lg"
                            />{" "}
                            Multi-user Account (1 - 50)
                          </li>
                          <li>
                            <FontAwesomeIcon
                              icon={faCheckCircle}
                              color="lightgreen"
                              size="lg"
                            />{" "}
                            UK & Ireland Datasets
                          </li>
                          <li>
                            <FontAwesomeIcon
                              icon={faCheckCircle}
                              color="lightgreen"
                              size="lg"
                            />{" "}
                            Site Comparison
                          </li>
                          <li>
                            <FontAwesomeIcon
                              icon={faCheckCircle}
                              color="lightgreen"
                              size="lg"
                            />{" "}
                            Additional Modules
                          </li>
                          <li>
                            <FontAwesomeIcon
                              icon={faCheckCircle}
                              color="grey"
                              size="lg"
                            />{" "}
                            Engineering Design Support
                          </li>
                          <li>
                            <FontAwesomeIcon
                              icon={faCheckCircle}
                              color="grey"
                              size="lg"
                            />{" "}
                            Module Bundle Discounts
                          </li>
                          <li>
                            <FontAwesomeIcon
                              icon={faCheckCircle}
                              color="grey"
                              size="lg"
                            />{" "}
                            Early Access & Beta Testing
                          </li>
                        </ul>
                      </div>
                      <div className="pricing-btn mt-35">
                        {/* <a
                          className="main-btn"
                          href="#"
                          onClick={() => setDemoSelected(true)}
                        >
                          Buy
                        </a> */}
                        {/* {activatedDeals >= 100 ? ( */}
                        <a
                          className="main-btn"
                          style={{ width: "100%" }}
                          href="#"
                          onClick={() =>
                            handleToolBuy(
                              Object.keys(toolInfo).filter(
                                (tool) =>
                                  toolInfo[tool].info.name === "Vekta Digital +"
                              ),
                              "year"
                            )
                          }
                        >
                          {loadingCheckout.year ? (
                            <BeatLoader
                              style={{ height: "100%" }}
                              color="white"
                            />
                          ) : (
                            "Buy"
                          )}
                        </a>
                        {/* ) : (
                          <div
                            style={{
                              display: "flex",
                              flexDirection: "column",
                              gap: "8px",
                            }}
                          >
                            <span>
                              * Deal only applies to the first 100{" "}
                              <u>activated</u> product keys
                            </span>
                            <a
                              className="main-btn"
                              href="/#"
                              onClick={() => {
                                if (user) {
                                  const functions = getFunctions(
                                    app,
                                    "us-central1"
                                  );
                                  const dealProduct = httpsCallable(
                                    functions,
                                    "getDealProductKey"
                                  );

                                  setLoadingCheckout((loadingCheckout) => ({
                                    ...loadingCheckout,
                                    year: true,
                                  }));

                                  dealProduct()
                                    .then((result) => {
                                      console.log("Product key sent");
                                      setLoadingCheckout((loadingCheckout) => ({
                                        ...loadingCheckout,
                                        year: false,
                                      }));
                                    })
                                    .catch((error) => {
                                      // Getting the Error details.
                                      console.log(error.code);
                                    });
                                  console.log("Send a Product Key to user");
                                }
                                
                                setLoginSelected(true);
                              }}
                            >
                              {loadingCheckout.year ? (
                                <BeatLoader
                                  style={{ height: "100%" }}
                                  color="white"
                                />
                              ) : (
                                "Activate Deal"
                              )}
                            </a>
                          </div>
                        )} */}
                      </div>
                      <div className="pricing-rebon">
                        <span
                          style={{
                            fontWeight: "600",
                            // background:
                            //   "linear-gradient(90deg, rgba(0,232,255,1) 0%, rgba(255,0,254,1) 100%)",
                          }}
                        >
                          Most Popular
                        </span>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-4 col-md-6 wow animated fadeInRight">
                    <div className="pricing-one__single center">
                      <div className="pricig-heading">
                        <h6 style={{ margin: "0" }}>Enterprise</h6>
                        <div className="price-range">
                          <sup></sup> <span>Contact Us</span>
                        </div>
                      </div>
                      <div className="pricig-body">
                        <ul>
                          <li>
                            <FontAwesomeIcon
                              icon={faCheckCircle}
                              color="lightgreen"
                              size="lg"
                            />{" "}
                            Global Datasets
                          </li>
                          <li>
                            <FontAwesomeIcon
                              icon={faCheckCircle}
                              color="lightgreen"
                              size="lg"
                            />{" "}
                            Weather Analysis
                          </li>
                          <li>
                            <FontAwesomeIcon
                              icon={faCheckCircle}
                              color="lightgreen"
                              size="lg"
                            />{" "}
                            Elevation Analysis
                          </li>
                          <li>
                            <FontAwesomeIcon
                              icon={faCheckCircle}
                              color="lightgreen"
                              size="lg"
                            />{" "}
                            Data Search
                          </li>
                          <li>
                            <FontAwesomeIcon
                              icon={faCheckCircle}
                              color="lightgreen"
                              size="lg"
                            />{" "}
                            Vekta Yield
                          </li>
                          <li>
                            <FontAwesomeIcon
                              icon={faCheckCircle}
                              color="lightgreen"
                              size="lg"
                            />{" "}
                            Project Sharing
                          </li>
                          <li>
                            <FontAwesomeIcon
                              icon={faCheckCircle}
                              color="lightgreen"
                              size="lg"
                            />{" "}
                            Project Dashboard
                          </li>
                          <li>
                            <FontAwesomeIcon
                              icon={faCheckCircle}
                              color="lightgreen"
                              size="lg"
                            />{" "}
                            Custom Map Drawing
                          </li>
                          <li>
                            <FontAwesomeIcon
                              icon={faCheckCircle}
                              color="lightgreen"
                              size="lg"
                            />{" "}
                            Multi-user Account (50+)
                          </li>
                          <li>
                            <FontAwesomeIcon
                              icon={faCheckCircle}
                              color="lightgreen"
                              size="lg"
                            />{" "}
                            UK & Ireland Datasets
                          </li>
                          <li>
                            <FontAwesomeIcon
                              icon={faCheckCircle}
                              color="lightgreen"
                              size="lg"
                            />{" "}
                            Site Comparison
                          </li>
                          <li>
                            <FontAwesomeIcon
                              icon={faCheckCircle}
                              color="lightgreen"
                              size="lg"
                            />{" "}
                            Additional Modules
                          </li>
                          <li>
                            <FontAwesomeIcon
                              icon={faCheckCircle}
                              color="lightgreen"
                              size="lg"
                            />{" "}
                            Engineering Design Support
                          </li>
                          <li>
                            <FontAwesomeIcon
                              icon={faCheckCircle}
                              color="lightgreen"
                              size="lg"
                            />{" "}
                            Module Bundle Discounts
                          </li>
                          <li>
                            <FontAwesomeIcon
                              icon={faCheckCircle}
                              color="lightgreen"
                              size="lg"
                            />{" "}
                            Early Access & Beta Testing
                          </li>
                        </ul>
                      </div>
                      <div className="pricing-btn mt-35">
                        <a
                          className="main-btn"
                          style={{ width: "100%" }}
                          href="#"
                          onClick={() => {
                            setPage("contact");
                            document.getElementById(
                              "landing-page"
                            ).scrollTop = 0;
                          }}
                        >
                          Contact Us
                        </a>
                      </div>
                      {/* <div className="pricing-rebon">
                        <span>Coming Soon</span>
                      </div> */}
                    </div>
                  </div>
                </div>
              </div>
            )}
            <p style={{ textAlign: "center" }}>
              *International buyers, please note that no additional taxes will
              be applied to the purchase of any of the above licenses.
            </p>
          </div>
        </div>
      </section>
    </>
  );
}

export default PricingHomeOne;
