import React, { useRef, useState } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick-theme.css";
import "slick-carousel/slick/slick.css";
import videoSlideOne from "../assets/images/vd-image-2.jpg";
import videoSlideTwo from "../assets/images/vd-image-3.jpg";
import videoThumb from "../assets/images/vd-image-1.jpg";
import { PillButton, PrimeButton } from "../../styles/styles";
// import PopupVideo from '../PopupVideo.jsx';

function VideoPlayerHomeTwo({ className }) {
  const [showVideo, setshowVideoValue] = useState(false);
  const handleVideoShow = (e) => {
    e.preventDefault();
    setshowVideoValue(!showVideo);
  };
  const sliderRef = useRef();
  const settings = {
    autoplay: true,
    arrows: false,
    dots: false,
  };
  const sliderNext = () => {
    sliderRef.current.slickNext();
  };
  const sliderPrev = () => {
    sliderRef.current.slickPrev();
  };
  return (
    <>
      {/* {showVideo && (
                <PopupVideo
                    handler={(e) => handleVideoShow(e)}
                    videoSrc="//www.youtube.com/embed/EE7NqzhMDms?autoplay=1"
                />
            )} */}
      <section className={`appie-video-player-area pb-100 ${className || ""}`}>
        <div className="container">
          <div className="row">
            <div className="col-lg-8">
              <div className="appie-video-player-item">
                <div className="thumb">
                  <img src={videoThumb} alt="" />
                  <div className="video-popup">
                    {/* <a
                      onClick={(e) => handleVideoShow(e)}
                      role="button"
                      href="#"
                      className="appie-video-popup"
                    >
                      <i className="fas fa-play" />
                    </a> */}
                  </div>
                </div>
                <div className="content">
                  <h3 className="title">
                    Use our in built tool tips to see how it all comes together
                  </h3>
                  <p>
                    Discover how our platform simplifies your workflow with all
                    the tools you need in one place.
                  </p>
                  <PillButton
                    style={{ width: "fit-content", fontSize: "16px" }}
                    onClick={() =>
                      document.getElementById("pricing-cards").scrollIntoView()
                    }
                  >
                    Get started
                  </PillButton>
                </div>
              </div>
            </div>
            <div className="col-lg-4">
              <div
                className="appie-video-player-slider"
                style={{ position: "relative" }}
              >
                <span
                  onClick={sliderPrev}
                  className="prev slick-arrow"
                  style={{ display: "block" }}
                >
                  <i class="fa-solid fa-chevron-left fa-xl"></i>
                </span>
                <Slider ref={sliderRef} {...settings}>
                  <div className="item">
                    <img src={videoSlideOne} alt="" />
                  </div>
                  <div className="item">
                    <img src={videoSlideTwo} alt="" />
                  </div>
                </Slider>
                <span
                  onClick={sliderNext}
                  className="next slick-arrow"
                  style={{ display: "block" }}
                >
                  <i class="fa-solid fa-chevron-right fa-xl"></i>
                </span>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default VideoPlayerHomeTwo;
