import React from "react";

function ProjectHomeThree({ className, setDemoSelected }) {
  return (
    <>
      <section className={`appie-project-3-area ${className} `}>
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div
                className="
                appie-project-3-box
                d-block d-md-flex
                justify-content-between
                align-items-center
                wow
                animated
                fadeInUp
              "
                data-wow-duration="2000ms"
                data-wow-delay="400ms"
              >
                <h3 className="title" style={{ fontWeight: "normal", fontSize: "28px" }}>
                  Discover our <strong>Vekta Cables tool -</strong>
                  <br></br> the perfect companion to Vekta Digital
                </h3>
                <a
                  className="main-btn"
                  href="https://vektacables.com"
                  target="_blank"
                >
                  Go to Vekta Cables
                </a>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default ProjectHomeThree;
