import React, { useRef } from "react";
// import Sponser1 from '../../assets/images/sponser-1.png';
// import Sponser10 from '../../assets/images/sponser-10.png';
// import Sponser2 from '../../assets/images/sponser-2.png';
// import Sponser3 from '../../assets/images/sponser-3.png';
// import Sponser4 from '../../assets/images/sponser-4.png';
// import Sponser5 from '../../assets/images/sponser-5.png';
// import Sponser6 from '../../assets/images/sponser-6.png';
// import Sponser7 from '../../assets/images/sponser-7.png';
// import Sponser8 from '../../assets/images/sponser-8.png';
// import Sponser9 from '../../assets/images/sponser-9.png';
// import sponserShape from '../../assets/images/sponser-shape.png';

import GEBCO from "../../media/gebco.png";
import OSM from "../../media/OSM.png";
import EMODNET from "../../media/emodnet.jpg";
import ERA5 from "../../media/era5.jpg";
import PyWake from "../../media/pywake.png";
import DTU from "../../media/DTU.png";
import MR from "../../media/marineRegions.png";
import CSP from "../../media/csp logo.png";
import RUK from "../../media/RUK logo.png";
import ORE from "../../media/ore.png";
import Slider from "react-slick";

function SponserHomeTwo({ className }) {
  const sliderRef = useRef();
  const settings = {
    autoplay: true,
    arrows: false,
    dots: false,
    speed: 500,
    slidesToShow: 5,
    slidesToScroll: 1,
    variableWidth: true,
    responsive: [
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          autoplay: true,
          variableWidth: true,
          speed: 500,
          arrows: false,
          dots: false,
        },
      },
    ],
  };
  const sliderNext = () => {
    sliderRef.current.slickNext();
  };
  const sliderPrev = () => {
    sliderRef.current.slickPrev();
  };

  const sponsers = [
    { image: EMODNET, url: "https://emodnet.ec.europa.eu/en", rec: true },
    { image: OSM, url: "https://www.openstreetmap.org/#map=6/54.910/-3.432" },
    { image: DTU, url: "https://wind.dtu.dk/" },
    {
      image: PyWake,
      url: "https://topfarm.pages.windenergy.dtu.dk/PyWake/",
      rec: true,
    },
    { image: GEBCO, url: "https://www.gebco.net/" },
    {
      image: ERA5,
      url: "https://climate.copernicus.eu/climate-reanalysis",
      rec: true,
    },
    { image: ORE, url: "https://ore.catapult.org.uk/", rec: true },
    { image: MR, url: "https://marineregions.org/", rec: true },
    { image: RUK, url: "https://www.renewableuk.com/", rec: true },
    { image: CSP, url: "https://celticseapower.co.uk/", rec: true },
  ];

  const CustomSlide = (props) => {
    const { index, item, ...otherProps } = props;
    return (
      <div {...otherProps}>
        <h3>{index}</h3>
      </div>
    );
  };

  return (
    <>
      <section className={`appie-sponser-area pb-100 pt-35 ${className}`}>
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="appie-section-title text-center">
                <h3 className="appie-title">
                  Powered By
                  <br />
                </h3>
                <p>
                  Trusted data and tools, offering full transparency and
                  accountability to ensure every insight is verified and
                  reliable.
                </p>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-12">
              {/* <div className="appie-sponser-box d-flex" style={{ gap: "20px" }}> */}
              <Slider ref={sliderRef} {...settings}>
                {sponsers.map((item) => {
                  return (
                    <div>
                      <a
                        className="sponser-item item"
                        style={{
                          marginTop: "8px",
                          marginRight: "16px",
                          // width: "100px",
                          // height: "80px",
                          // outline: "2px solid blue",
                          // boxShadow: "0px 0px 5px 0px #009ec67a",
                          borderRadius: "8px",
                          padding: "10px",
                        }}
                        href={item.url}
                        target="_blank"
                      >
                        <img
                          src={item.image}
                          style={
                            item.rec
                              ? {
                                  // width: "80px",
                                  height: "80px",
                                  objectFit: "scale-down",
                                  // outline: "2px solid green",
                                }
                              : {
                                  width: "80px",
                                  height: "80px",
                                  objectFit: "scale-down",
                                  // outline: "2px solid green",
                                }
                          }
                          alt=""
                        />
                      </a>
                    </div>
                  );
                })}
              </Slider>
              {/* </div> */}
              {/* <div className="appie-sponser-box item-2 d-flex justify-content-center"></div> */}
            </div>
          </div>
        </div>
        <div className="sponser-shape">
          {/* <img src={sponserShape} alt="" /> */}
        </div>
      </section>
    </>
  );
}

export default SponserHomeTwo;
