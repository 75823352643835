import React, { useState } from "react";
import { PillButton } from "../../styles/styles";
import WindPowerIcon from "@mui/icons-material/WindPower";
import { ReactComponent as YieldIcon } from "../../media/Yield.svg";

function ServicesHomeTwo({ className }) {
  const [advanced, setAdvanced] = useState(false);

  return (
    <>
      <section className={`appie-services-2-area ${className}`} id="service">
        <div className="container">
          <div className="row align-items-end">
            <div className="col-lg-6 col-md-8">
              <div className="appie-section-title">
                <h3 className="appie-title">How does it work</h3>
                {/* <h4>Basic Features</h4> */}
                <p>
                  A secure, cloud-based platform with essential tools for
                  <br></br>
                  seamless project collaboration and trusted data access!
                </p>
              </div>
            </div>
            <div className="col-lg-6 col-md-4">
              <div
                className="appie-section-title text-right"
                style={{ justifyContent: "flex-end", display: "flex" }}
              >
                {/* <a className="main-btn" href="#">
                  Discover advanced features{" "}
                  <i className="fal fa-arrow-right" />
                </a> */}
                <PillButton
                  style={{ width: "fit-content", fontSize: "16px" }}
                  onClick={() => setAdvanced((temp) => !temp)}
                >
                  {advanced
                    ? "Discover basic features"
                    : "Discover advanced features"}
                </PillButton>
              </div>
            </div>
          </div>
          {!advanced ? (
            <div className="row">
              <div className="col-lg-4 col-md-6">
                <div
                  className="appie-single-service-2 mt-30 wow animated fadeInUp"
                  data-wow-duration="2000ms"
                  data-wow-delay="200ms"
                >
                  <div className="icon">
                    <i class="fa-solid fa-earth-europe fa-xl"></i>
                  </div>
                  <h4 className="title">Global Datasets</h4>
                  <p>
                    Access trused global datasets in one place. Use included GIS
                    data or upload custom layers fro insights on power
                    infrastructure, bathymetry and wind climate.
                  </p>
                  {/* <a href="#">
                  Read Mor <i className="fal fa-arrow-right" />
                </a> */}
                </div>
              </div>
              <div className="col-lg-4 col-md-6">
                <div
                  className="appie-single-service-2 item-2 mt-30 wow animated fadeInUp"
                  data-wow-duration="2000ms"
                  data-wow-delay="400ms"
                >
                  <div className="icon">
                    <i>
                      <YieldIcon width={20} />
                    </i>
                  </div>
                  <h4 className="title">Energy Yield</h4>
                  <p>
                    Estimate site yield, AEP, Wake Effect, and Capacity Factor
                    quickly. Make informed decisions on site layout, turbine
                    selection, and weather data.
                  </p>
                  {/* <a href="#">
                  Read Mor <i className="fal fa-arrow-right" />
                </a> */}
                </div>
              </div>
              <div className="col-lg-4 col-md-6">
                <div
                  className="appie-single-service-2 item-3 mt-30 wow animated fadeInUp"
                  data-wow-duration="2000ms"
                  data-wow-delay="600ms"
                >
                  <div className="icon">
                    <i class="fa-solid fa-cloud-sun-rain fa-xl"></i>
                  </div>
                  <h4 className="title">Weather Analysis</h4>
                  <p>
                    Use ERA5 and custom weather data for analysis. Generate wind
                    roses,perform persistence analysis, and export reports in
                    seconds.
                  </p>
                  {/* <a href="#">
                  Read Mor <i className="fal fa-arrow-right" />
                </a> */}
                </div>
              </div>
              <div className="col-lg-4 col-md-6">
                <div
                  className="appie-single-service-2 item-4 mt-30 wow animated fadeInUp"
                  data-wow-duration="2000ms"
                  data-wow-delay="200ms"
                >
                  <div className="icon">
                    <i class="fa-solid fa-arrow-down-up-across-line fa-xl"></i>
                  </div>
                  <h4 className="title">Elevation Analysis</h4>
                  <p>
                    Get detailed offshore and onshore elevation data. Perform
                    slope and elevation analysis then export your charts.
                  </p>
                  {/* <a href="#">
                  Read Mor <i className="fal fa-arrow-right" />
                </a> */}
                </div>
              </div>
              <div className="col-lg-4 col-md-6">
                <div
                  className="appie-single-service-2 item-5 mt-30 wow animated fadeInUp"
                  data-wow-duration="2000ms"
                  data-wow-delay="400ms"
                >
                  <div className="icon">
                    <i class="fa-solid fa-magnifying-glass fa-xl"></i>
                  </div>
                  <h4 className="title">Data Search</h4>
                  <p>
                    Find what matters with our powerful search tool. Access
                    global ports, AIS data, and renewable power plant projects.
                  </p>
                  {/* <a href="#">
                  Read Mor <i className="fal fa-arrow-right" />
                </a> */}
                </div>
              </div>
              <div className="col-lg-4 col-md-6">
                <div
                  className="appie-single-service-2 item-6 mt-30 wow animated fadeInUp"
                  data-wow-duration="2000ms"
                  data-wow-delay="600ms"
                >
                  <div className="icon">
                    <i>
                      <WindPowerIcon style={{ fontSize: "large" }} />
                    </i>
                  </div>
                  <h4 className="title">Site Builder</h4>
                  <p>
                    Build, optimise, and compare various site layouts to find
                    the most efficient configuration for your project
                  </p>
                  {/* <a href="#">
                  Read Mor <i className="fal fa-arrow-right" />
                </a> */}
                </div>
              </div>
            </div>
          ) : (
            <div className="row">
              <div className="col-lg-4 col-md-6">
                <div
                  className="appie-single-service-2 mt-30 wow animated fadeInUp"
                  data-wow-duration="2000ms"
                  data-wow-delay="200ms"
                >
                  <div className="icon">
                    <i class="fa-solid fa-earth-europe fa-xl"></i>
                  </div>
                  <h4 className="title">Advanced Datasets</h4>
                  <p>
                    Access trused global datasets in one place. Use included GIS
                    data or upload custom layers fro insights on power
                    infrastructure, bathymetry and wind climate.
                  </p>
                  {/* <a href="#">
                  Read Mor <i className="fal fa-arrow-right" />
                </a> */}
                </div>
              </div>
              <div className="col-lg-4 col-md-6">
                <div
                  className="appie-single-service-2 item-2 mt-30 wow animated fadeInUp"
                  data-wow-duration="2000ms"
                  data-wow-delay="400ms"
                >
                  <div className="icon">
                    <i>
                      <YieldIcon width={20} />
                    </i>
                  </div>
                  <h4 className="title">Advanced Energy Yield</h4>
                  <p>
                    Estimate site yield, AEP, Wake Effect, and Capacity Factor
                    quickly. Make informed decisions on site layout, turbine
                    selection, and weather data.
                  </p>
                  {/* <a href="#">
                  Read Mor <i className="fal fa-arrow-right" />
                </a> */}
                </div>
              </div>
              <div className="col-lg-4 col-md-6">
                <div
                  className="appie-single-service-2 item-3 mt-30 wow animated fadeInUp"
                  data-wow-duration="2000ms"
                  data-wow-delay="600ms"
                >
                  <div className="icon">
                    <i class="fa-solid fa-cloud-sun-rain fa-xl"></i>
                  </div>
                  <h4 className="title">Advanced Weather Sources</h4>
                  <p>
                    Use ERA5 and custom weather data for analysis. Generate wind
                    roses,perform persistence analysis, and export report.
                  </p>
                  {/* <a href="#">
                  Read Mor <i className="fal fa-arrow-right" />
                </a> */}
                </div>
              </div>
              <div className="col-lg-4 col-md-6">
                <div
                  className="appie-single-service-2 item-4 mt-30 wow animated fadeInUp"
                  data-wow-duration="2000ms"
                  data-wow-delay="200ms"
                >
                  <div className="icon">
                    <i class="fa-solid fa-map-location fa-xl"></i>
                  </div>
                  <h4 className="title">Map Drawing</h4>
                  <p>
                    Produce professional, custom map drawings with easy-to-use
                    tools. Add logos, metadata, and export for sharing or
                    reporting.
                  </p>
                  {/* <a href="#">
                  Read Mor <i className="fal fa-arrow-right" />
                </a> */}
                </div>
              </div>
              <div className="col-lg-4 col-md-6">
                <div
                  className="appie-single-service-2 item-5 mt-30 wow animated fadeInUp"
                  data-wow-duration="2000ms"
                  data-wow-delay="400ms"
                >
                  <div className="icon">
                    <i>
                      <WindPowerIcon style={{ fontSize: "large" }} />
                    </i>
                  </div>
                  <h4 className="title">Advanced Site Builder</h4>
                  <p>
                    Find what matters with our powerful search tool. Access
                    global ports, AIS data, and renewable power plant projects.
                  </p>
                  {/* <a href="#">
                  Read Mor <i className="fal fa-arrow-right" />
                </a> */}
                </div>
              </div>
              <div className="col-lg-4 col-md-6">
                <div
                  className="appie-single-service-2 item-6 mt-30 wow animated fadeInUp"
                  data-wow-duration="2000ms"
                  data-wow-delay="600ms"
                >
                  <div className="icon">
                    <i class="fa-solid fa-table-columns fa-xl"></i>
                  </div>
                  <h4 className="title">Project Dashboard</h4>
                  <p>
                    Build, optimise, and compare various site layouts to find
                    the most efficient configuration for you project's success.
                  </p>
                  {/* <a href="#">
                  Read Mor <i className="fal fa-arrow-right" />
                </a> */}
                </div>
              </div>
            </div>
          )}
        </div>
      </section>
    </>
  );
}

export default ServicesHomeTwo;
