import React, { useEffect, useState } from "react";
import useToggle from "./pricingScetion/useToggle.js";
import BackToTop from "./BackToTop.jsx";

import FeaturesHomeOne from "./explanationSection/FeaturesHomeOne.jsx";
import PricingHomeOne from "./pricingScetion/PricingHomeOne.jsx";
import BlogHomeThree from "./feedbackSection/BlogHomeThree.jsx";
import DownloadHomeThree from "./feedbackSection/DownloadHomeThree.jsx";
import CounterArea from "./faqSection/CounterArea.jsx";
import ServicesHomeTwo from "./explanationSection/ServicesHomeTwo.jsx";
import VideoPlayerHomeTwo from "./faqSection/VideoPlayerHomeTwo.jsx";

import Drawer from "./Drawer.jsx";
import FooterHomeSix from "./footerSection/FooterHomeSix.jsx";
import HeaderHomeSix from "./navigationSection/HeaderHomeSix.jsx";
import HeroHomeSix from "./navigationSection/HeroHomeSix.jsx";
import HeroNews from "./navigationSection/HeroNews.jsx";
import SponserHomeTwo from "./footerSection/SponserHomeTwo.jsx";
import FaqHomeOne from "./faqSection/FaqHomeOne.jsx";
import ProjectHomeThree from "./footerSection/ProjectHomeThree.jsx";

import "./assets/css/main.css";
import "./assets/css/style.css";
import "./assets/css/default.css";
import "./assets/css/bootstrap.min.css";
import "./assets/css/custom-animated.css";
// import "./assets/css/font-awesome.min.css";
import "./assets/css/magnific-popup.css";
import FooterHomeThree from "./footerSection/FooterHomeThree.jsx";
import TestimonialHomeEight from "./faqSection/TestimonialHomeEight.jsx";
import Blogs from "./navigationSection/Blogs.jsx";
import Forms from "./feedbackSection/Forms.jsx";
// import "./assets/css/magnific.dark.css";
// import "./assets/css/magnific.rtl.css";

import Construction from "./assets/images/construction2.svg";
import InsightsImg from "./assets/images/slide-insights.jpg";
import Login from "./modals/Login.js";
import { getProductInfo } from "./helperFunctions.js";

function HomeSix() {
  const [drawer, drawerAction] = useToggle(false);
  const [page, setPage] = useState("home");
  const [loginSelected, setLoginSelected] = useState(false);

  const [toolInfo, setToolInfo] = useState({});

  useEffect(() => {
    const getToolInfo = async () => {
      const tools = await getProductInfo();

      console.log(tools);

      setToolInfo(tools);
    };

    getToolInfo();
  }, []);

  return (
    <div
      className="landing-page"
      id="landing-page"
      style={{ overflowY: "scroll", overflowX: "hidden", height: "100vh" }}
    >
      <Drawer drawer={drawer} action={drawerAction.toggle} setPage={setPage}/>
      <HeaderHomeSix
        action={drawerAction.toggle}
        setPage={setPage}
        setLoginSelected={setLoginSelected}
      />
      {page === "home" ? (
        <>
          <HeroHomeSix setPage={setPage} setLoginSelected={setLoginSelected} />
          <ServicesHomeTwo className="pt-90" />
          <BackToTop className="back-to-top-6" />
          {/* <FeaturesHomeOne className="appie-features-6-area" /> */}
          <CounterArea />
          <VideoPlayerHomeTwo />
          {/* <DownloadHomeThree className="pt-0 pb-100" /> */}
          {/* <TestimonialHomeEight /> */}
          <PricingHomeOne
            className="appie-pricing-6-area"
            setLoginSelected={setLoginSelected}
            setPage={setPage}
            toolInfo={toolInfo}
          />
          {/* <BlogHomeThree /> */}
          <SponserHomeTwo />
          <FaqHomeOne setPage={setPage} />
        </>
      ) : page === "insights" ? (
        <>
          <HeroNews
            title="Insights"
            breadcrumb={[
              { link: "/", title: "Home" },
              { link: "/", title: "Insights" },
            ]}
            background={"./images/slide-insights.jpg"}
          />
          <section
            className="blogpage-section"
            style={{ background: "#eef1f6" }}
          >
            <div className="container">
              <div className="row">
                {/* <div className="col-lg-8 col-md-7"> */}
                <Blogs />
                {/* </div> */}
                {/* <div className="col-lg-4 col-md-5">
                  <BlogSideBar />
                </div> */}
              </div>
            </div>
          </section>
        </>
      ) : page === "product-updates" ? (
        <>
          <HeroNews
            title="Product Updates"
            breadcrumb={[
              { link: "/", title: "Home" },
              { link: "/", title: "Product-Updates" },
            ]}
            background={"./images/slide-software.jpg"}
          />
          <section
            className="row justify-content-center"
            style={{ height: "100vh", background: "#eef1f6" }}
          >
            <img
              src={Construction}
              width={"60%"}
              style={{ height: "fit-content" }}
            />
          </section>
        </>
      ) : (
        <>
          <HeroNews
            title="Contact"
            breadcrumb={[
              { link: "/", title: "Home" },
              { link: "/", title: "Contact" },
            ]}
            background={"./images/slide-contact.jpg"}
          />
          <Forms />
        </>
      )}

      {page !== "contact" && (
        <ProjectHomeThree className={"home-four-project"} />
      )}
      <FooterHomeThree setPage={setPage} />

      <Login
        loginSelected={loginSelected}
        setLoginSelected={setLoginSelected}
      />
    </div>
  );
}

export default HomeSix;
